import { createContext, useContext, FC, ReactNode } from 'react';

type ServerVarsContextType = {
    isBot?: boolean;
    isDesktop?: boolean;
    isMobile?: boolean;
    isTablet?: boolean;
    isItemTileShorterHeight?: boolean | null;
};

export const ServerVarsContext = createContext<ServerVarsContextType>({});

export const ServerVarsContextConsumer = ServerVarsContext.Consumer;

type ServerVarsProviderProps = {
    children: ReactNode;
} & ServerVarsContextType;

export const ServerVarsContextProvider: FC<ServerVarsProviderProps> = ({
    children,
    isBot,
    isDesktop,
    isMobile,
    isTablet,
    isItemTileShorterHeight,
}) => {
    return (
        <ServerVarsContext.Provider
            value={{
                isBot,
                isDesktop,
                isMobile,
                isTablet,
                isItemTileShorterHeight,
            }}
        >
            {children}
        </ServerVarsContext.Provider>
    );
};

export const useServerVarsContext = (): ServerVarsContextType => {
    return useContext(ServerVarsContext);
};
